<template>
  <div class="h-100 row">
    <div class="h-100 text-center col-12" v-if="mode === 'start'">
      <div class="d-flex align-items-center justify-content-center h-25 row">
        <div class="text-secondary text-center col-12">
          <h1><i class="me-3 text-primary fas fa-hammer"></i>{{ dictionary.pageTitle }}</h1>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-center row" style="height: 30%">
        <div :class="(selected === 'A4') ? 'formTypeSelected' : ''"
            @click="formCardClick('A4')"
            class="d-flex align-items-center justify-content-center me-1 px-1 py-5 h-100 col-auto formTypeCard formTypeCardFull">
          <div class="my-3 text-center row">
            <div class="mb-4 col-12">
              <img style="width: 80px" src="assets/a4.png" alt="a4">
            </div>
            <div class="col-12">
              <h4 class="fw-bold">Badge A4</h4>
              <p class="m-0">210 x 297 mm</p>
            </div>
          </div>
        </div>
        <div :class="(selected === 'CR80') ? 'formTypeSelected' : ''"
             @click="formCardClick('CR80')"
             class="d-flex align-items-center justify-content-center mx-1 px-1 py-5 h-100 col-auto formTypeCard formTypeCardFull">
          <div class="text-center row">
            <div class="mb-4 col-12">
              <img style="width: 80px" src="assets/credit-card.png" alt="credit card">
            </div>
            <div class="col-12">
              <h4 class="fw-bold">Badge "Carte bleu"</h4>
              <p class="m-0">85,7 x 54,03 mm</p>
            </div>
          </div>
        </div>
        <div :class="(selected === 'papillon') ? 'formTypeSelected' : ''"
             @click="formCardClick('papillon')"
             class="d-flex align-items-center justify-content-center mx-1 px-1 py-5 h-100 col-auto formTypeCard formTypeCardFull">
          <div class="text-center row">
            <div class="mb-4 col-12">
              <h1 class="formPicto fas fa-cut"></h1>
            </div>
            <div class="col-12">
              <h4 class="fw-bold">Badge "Papillon"</h4>
              <p class="m-0">100 x 120 mm</p>
            </div>
          </div>
        </div>
        <div :class="(selected === 'PapillonsV2') ? 'formTypeSelected' : ''"
             @click="formCardClick('PapillonsV2')"
             class="d-flex align-items-center justify-content-center mx-1 px-1 py-5 h-100 col-auto formTypeCard formTypeCardFull">
          <div class="text-center row">
            <div class="mb-4 col-12">
              <h1 class="formPicto fas fa-bug"></h1>
            </div>
            <div class="col-12">
              <h4 class="fw-bold">Badge "Coccinelle"</h4>
              <p class="m-0">86  x 120 mm</p>
            </div>
          </div>
        </div>
        <div :class="(selected === 'etiquette') ? 'formTypeSelected' : ''"
             @click="formCardClick('etiquette')"
             class="d-flex align-items-center justify-content-center mx-1 px-1 py-5 h-100 col-auto formTypeCard formTypeCardFull">
          <div class="text-center row">
            <div class="mb-4 col-12">
              <h1 class="fa-sticky-note formPicto fas"></h1>
            </div>
            <div class="col-12">
              <h4 class="fw-bold">Etiquette</h4>
              <p class="m-0">70 x 30 mm</p>
            </div>
          </div>
        </div>
        <div
            :class="(selected === 'US Letter') ? 'formTypeSelected' : ''"
            @click="formCardClick('US Letter')"
            class="d-flex align-items-center justify-content-center mx-1 px-1 py-5 h-100 col-auto formTypeCard formTypeCardFull">
          <div class="text-center row">
            <div class="mb-4 col-12">
              <h1 class="fa-envelope-open formPicto fas"></h1>
            </div>
            <div class="col-12">
              <h4 class="fw-bold">US Letter</h4>
              <p class="m-0">215.9 x 279.4 mm</p>
            </div>
          </div>
        </div>
        <div :class="(selected === 'import') ? 'formTypeSelected' : ''"
             @click="formCardClick('import')"
             class="d-flex align-items-center justify-content-center ms-1 px-1 py-5 h-100 col-auto formTypeCard formTypeCardOutline">
          <div class="text-center row">
            <div class="col-12">
              <h1 class="formPicto fas fa-file-import"></h1>
            </div>
            <div class="col-12">
              <h4 class="fw-bold">Importer un badge</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-center h-25 row">
        <div class="text-center col-12" v-if="mode === 'start'">
          <button :disabled="selected === ''" :class="selected === '' ? 'btn-outline-dark' : ' text-light btn-success'" @click="validateClick" class="btn btn-lg">{{ dictionary.continue.toUpperCase() }}</button>
        </div>
        <div class="col-12">
          <input @change="readImportData" accept="application/JSON" ref="importHiddenInput" type="file" style="visibility: hidden">
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import VueNotifications from 'vue-notifications';
import { mapGetters } from 'vuex';
import BadgeDefaultContent from '../configuration/BadgeDefaultContent.json';

export default {
  name: 'NewWebsiteWizzard',
  data()
  {
    return {
      selected: '',
      mode: 'start',
    };
  },
  computed: {
    ...mapGetters(['dictionary']),
  },
  methods: {
    importWebsite(content)
    {
      this.$store.commit('setBadgeContent', { content });
    },
    formCardClick(selected)
    {
      this.selected = selected;
    },
    validateClick()
    {
      if (this.selected === 'import')
        this.$refs.importHiddenInput.click();
      else
      {
        this.$store.commit('setBadgeContent', {
          content: {
            ...BadgeDefaultContent[this.selected],
            origin: this.selected,
          },
          dbSync: true,
        });
      }
    },
    readImportData(ev)
    {
      const file = ev.target.files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        try {
          const object = JSON.parse(e.target.result);
          if (object)
            this.importWebsite(object);
        } catch (ex)
        {
          console.log(`Invalid badge content file : ${ex.message}`);
        }
      };
      reader.readAsText(file);
    },
  },
  notifications: {
    websiteImported: {
      type: VueNotifications.types.success,
      title: 'Badge importé !',
      message: 'Le badge a été importé avec succès',
    },
    blankSiteCreated: {
      type: VueNotifications.types.success,
      title: 'Badge crée !',
      message: 'Amusez vous bien :)',
    },
  },
};

</script>

<style scoped>

.formTypeCard {
  border-radius: 15px;
  color: white;
  cursor: pointer;
}

.formTypeCardFull {
  background-color: var(--bs-secondary);
  border: 2px solid var(--bs-secondary);
}

.formTypeCardOutline {
  border: 2px solid var(--bs-secondary);
  color: var(--bs-secondary)
}

.formTypeSelected {
  border: 2px solid var(--bs-primary);
  color: var(--bs-primary);
}

.formTypeCard:not([disabled]):hover {
  border: 2px solid var(--bs-primary);
  color: var(--bs-primary);
}

</style>
