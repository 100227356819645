<template>
  <div class="row">
    <form class="col-12">
      <StyleEditor v-model="style" :properties="['backgroundColor','fontFamily','fontWeight']">
        <template v-slot:form-start>
          <div class="mb-3">
            <label class="form-label" for="orientationSelect">Orientation du badge</label>
            <select v-model="orientation" class="form-control" id="orientationSelect">
              <option value="h" :disabled="badgeContent.origin === 'papillon' || badgeContent.origin === 'PapillonsV2'"> Horizontale</option>
              <option value="v">Verticale</option>
            </select>
          </div>
          <div class="mb-3">
            <label class="form-label" for="mirrorSelect">Mirroir</label>
            <select v-model="mirror" class="form-control" id="mirrorSelect">
              <option value="">Pas de mirroir</option>
              <option value="x">Horizontal</option>
              <option value="y">Vertical</option>
              <option value="yup">Vertical Inversé (badges 8.5 - 13 cm)</option>
              <option value="xy">Horizontal et Vertical</option>
            </select>
          </div>
        </template>
        <template v-slot:form-end>
          <InputImageUpload v-model="backgroundImage" :title="dictionary.styleEditor.backgroundImage" class="mb-3"/>
        </template>
      </StyleEditor>
    </form>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import StyleEditor from '../../Utilities/StyleEditor.vue';
import InputImageUpload from '../../Utilities/InputImageUpload.vue';

export default {
  name: 'BodyConfig',
  components: {
    InputImageUpload,
    StyleEditor,
  },
  computed: {
    ...mapGetters(['dictionary', 'badgeContent']),
    style: {
      get()
      {
        return this.badgeContent || {};
      },
      set(newStyle)
      {
        this.$store.commit('setBadgeContent', { content: { ...this.badgeContent, ...newStyle }, dbSync: true });
      },
    },
    orientation: {
      get()
      {
        return this.badgeContent.orientation || 'h';
      },
      set(newOrientation)
      {
        this.$store.commit('setBadgeContent', { content: { ...this.badgeContent, orientation: newOrientation }, dbSync: true });
      },
    },
    mirror: {
      get()
      {
        let out = '';
        if (this.badgeContent.format.mirrorX)
          out += 'x';
        if (this.badgeContent.format.mirrorY)
          out += 'y';
        if (this.badgeContent.format.mirrorYUP)
          out += 'yup';
        return out;
      },
      set(newMirrorValue)
      {
        this.$store.commit('setBadgeContent', {
          content: {
            ...this.badgeContent,
            format: {
              ...this.badgeContent.format,
              mirrorX: newMirrorValue.includes('x') || false,
              mirrorY: (newMirrorValue.includes('y') && !newMirrorValue.includes('yup')),
              mirrorYUP: newMirrorValue.includes('yup') || false,
            },
          },
          dbSync: true,
        });
      },
    },
    backgroundImage: {
      get()
      {
        return this.badgeContent.backgroundImage || '';
      },
      set(newBackgroundImage)
      {
        this.$store.commit('setBadgeContent', { content: { ...this.badgeContent, backgroundImage: newBackgroundImage }, dbSync: true });
      },
    },
  },
};

</script>

<style scoped>

</style>
